import React from "react";

import Accent from "../Accent";

import styles from "./styles.module.less";

const IntroHeader = ({ header, par, img, alt, paddingBottom = "" }) => {
    const customInnerStyle = paddingBottom ? { paddingBottom: "0px" } : {};

    return (
        <div className={styles.IntroHeader}>
            <div className={styles.inner} style={customInnerStyle}>
                {img && <img src={img} alt={alt} />}
                <h1>{header}</h1>
                <Accent />
                <p>{par}</p>
            </div>
        </div>
    );
};

export default IntroHeader;

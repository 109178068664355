import React from "react";
import Helmet from "react-helmet";

import Layout from "../../components/Layout";
import Header from "../../components/Header";
import IntroHeader from "../../components/IntroHeader";
import Divider from "../../components/Divider";
import styles from "./styles.module.less";
import StaticText from "../../components/StaticText";

import img from "../../images/servicing.png";

const Servicing = ({ staticTextItems }) => {
    const header = (
        <Header
            active={["tools-and-resources", "servicing"]}
            theme="blue-ribbon"
        />
    );

    const introHeader = "Servicing";
    const par = "Please find important Bay Equity Servicing information below.";

    return (
        <Layout hasHero={false} header={header}>
            <Helmet>
                <title>Servicing | Bay Equity Home Loans</title>
                <meta
                    name="description"
                    content="Please find important Bay Equity Servicing. If you don’t find the answer you’re looking for, give us a call. We’re here to help."
                />
            </Helmet>
            <IntroHeader header={introHeader} par={par} img={img} />
            <Divider />
            <div className={styles.body}>
                <StaticText
                    id="servicing-body"
                    items={staticTextItems}></StaticText>
            </div>
        </Layout>
    );
};

export default Servicing;
